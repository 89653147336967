



















































import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { Component, Watch } from "vue-property-decorator";
import VerticalDivider from "@/components/VerticalDivider.vue";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import PaymentStudents from "@/views/AutomaticPaymentsOverview/PaymentStudents.vue";
import { IStudent } from "@/interfaces/IStudent";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import PaymentHeader from "@/views/AutomaticPaymentsOverview/PaymentHeader.vue";

const AppModule = namespace("app");
const PaymentRequestModule = namespace("payment-request");

@Component({
  components: {
    PaymentHeader,
    PaymentStudents,
    FscCard,
    SaveButton,
    AbortButton,
    HintMessage,
    VerticalDivider,
    FscSimpleCard,
  },
})
export default class PaymentRequest extends mixins(TabMixin) {
  public name = "PaymentRequest";

  @PaymentRequestModule.Action("create")
  protected createPaymentRequest: any;

  @PaymentRequestModule.Getter("getSuccess")
  protected successPaymentRequest: any;

  @PaymentRequestModule.Getter("getIsLoading")
  protected isLoadingPaymentRequest: any;

  @PaymentRequestModule.Mutation("SET_SUCCESS")
  protected SET_SUCCESS: any;

  @AppModule.Action("setSelectedClassicPayStudents")
  public setSelectedClassicPayStudents: any;

  @AppModule.Getter("getSelectedClassicPayStudents")
  protected getSelectedClassicPayStudents!: any;

  protected selectedClassicPayStudents: Array<any> = [];

  protected onAbort(): void {
    this.closeCurrentTab();
  }

  protected onDeleteStudent(item: IStudent, index: number): void {
    this.selectedClassicPayStudents.splice(index, 1);
  }

  protected async onSubmit(): Promise<void> {
    await this.createPaymentRequest({
      resource: "payment-requests/create-multiple",
      data: this.selectedClassicPayStudents,
    });

    if (this.successPaymentRequest) {
      this.$toasted.success("Success!");
      this.closeCurrentTab();
    }
  }

  public mounted(): void {
    this.initSelectedClassicPayStudents();
  }

  protected initSelectedClassicPayStudents(): void {
    this.selectedClassicPayStudents = [];
    this.getSelectedClassicPayStudents.forEach((education: any) => {
      this.selectedClassicPayStudents.push({
        student: education.student,
        studentEducationId: education.studentEducationId,
        amountInEur: education.saldo.saldo < 0 ? Math.abs(education.saldo.saldo) : null,
      });
    });
    if (this.selectedClassicPayStudents.length === 0) {
      this.$nextTick(() => {
        this.closeCurrentTab();
      });
    }
  }

  @Watch("getSelectedClassicPayStudents")
  protected getSelectedClassicPayStudentsWatcher(): void {
    this.initSelectedClassicPayStudents();
  }

  public beforeDestroy(): void {
    this.$root.$emit("before-destroyed-component", {
      component: "ClassicPay",
      success: this.successPaymentRequest,
    });
    this.SET_SUCCESS(false);
    this.setSelectedClassicPayStudents(null);
  }
}
